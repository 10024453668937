import {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useLayout} from '../contexts/LayoutContext';
import {useSession} from '../contexts/SessionContext';

const useLayoutSidebarItems = () => {
  const [, , selectors] = useSession();
  const {t} = useTranslation('pages');

  const SIDEBAR_TOP_ITEMS = [
    {
      id: 'home',
      icon: 'fa-solid fa-house',
      path: '/admin',
      label: t('Sidebar.Home.label'),
    },
    {
      id: 'products',
      icon: 'fa-solid fa-cart-shopping',
      path: '/admin/products',
      label: t('Sidebar.Products.label'),
      children: [
        {
          id: 'products-add',
          icon: 'fa-solid fa-circle',
          path: '/admin/products/add',
          label: t('Sidebar.Products.AddProduct.label'),
        },
        {
          id: 'products-import',
          icon: 'fa-solid fa-circle',
          path: '/admin/products/import',
          label: t('Sidebar.Products.ImportProducts.label'),
        },
        {
          id: 'products-price-lists',
          icon: 'fa-solid fa-circle',
          path: '/admin/products/price-list',
          label: t('Sidebar.Products.PriceLists.label'),
        },
        {
          id: 'products-categories',
          icon: 'fa-solid fa-circle',
          path: '/admin/products/categories',
          label: t('Sidebar.Products.Categories.label'),
        },
        {
          id: 'products-variants',
          icon: 'fa-solid fa-circle',
          path: '/admin/products/variants',
          label: t('Sidebar.Products.Variants.label'),
        },
      ],
    },
    {
      id: 'purchase-orders',
      icon: 'fa-solid fa-bag-shopping',
      path: '/admin/purchase-orders',
      label: t('Sidebar.PurchaseOrders.label'),
    },
    {
      id: 'blinks',
      icon: 'fa-solid fa-paper-plane',
      path: '/admin/blinks',
      label: t('Sidebar.Blinks.label'),
    },
    {
      id: 'users',
      icon: 'fa-solid fa-user',
      path: '/admin/users',
      label: t('Sidebar.Users.label'),
      children: [
        {
          id: 'products-custom-fields',
          icon: 'fa-solid fa-circle',
          path: '/admin/users/custom-fields',
          label: t('Sidebar.Users.CustomFields.label'),
        },
      ],
    },
    {
      id: 'store',
      icon: 'fa-solid fa-dollar-sign',
      path: '/',
      label: t('Sidebar.GoToStore.label'),
      openInNewWindow: true,
    },
  ];

  const SIDEBAR_BOTTOM_ITEMS = [
    {
      id: 'settings',
      icon: 'fa-solid fa-gear',
      path: '/admin/settings',
      label: t('Sidebar.Settings.label'),
    },
    {
      id: 'help',
      icon: 'fa-solid fa-circle-question',
      href: 'https://api.whatsapp.com/send/?phone=5491155797437&text&type=phone_number&app_absent=0',
      label: t('Sidebar.Help.label'),
      target: '_blank',
    },
    {
      id: 'logout',
      icon: 'fa-solid fa-right-from-bracket',
      path: '/logout',
      label: t('Sidebar.SignOut.label'),
    },
  ];

  if (selectors.isSellerSession) {
    const productsRouteIndex = SIDEBAR_TOP_ITEMS.findIndex((route) => route.id === 'products');
    const settingsRouteIndex = SIDEBAR_BOTTOM_ITEMS.findIndex((route) => route.id === 'settings');
    SIDEBAR_TOP_ITEMS.splice(productsRouteIndex, 1);
    SIDEBAR_BOTTOM_ITEMS.splice(settingsRouteIndex, 1);
  }

  return [SIDEBAR_TOP_ITEMS, SIDEBAR_BOTTOM_ITEMS];
};

const useLayoutTopBar = (type) => {
  const [, layoutActions] = useLayout();

  useEffect(() => {
    layoutActions.setTopBarType(type);
    return () => {
      layoutActions.setTopBarType(null);
    };
  }, [layoutActions, type]);
};

const useLayoutTitle = (title) => {
  const [, layoutActions] = useLayout();

  const memoized = useMemo(
    () => ({
      label: title.label,
      icon: title.icon,
    }),
    [title.label, title.icon],
  );

  useEffect(() => {
    layoutActions.setTitle(memoized);
    return () => {
      layoutActions.setTitle(null);
    };
  }, [layoutActions, memoized]);
};

const useLayoutButtons = () => {
  const [layoutState, layoutActions] = useLayout();
  return [layoutState.buttons, layoutActions.setButtons, layoutActions.clearButtons];
};

export {useLayoutSidebarItems, useLayoutTopBar, useLayoutTitle, useLayoutButtons};
